import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Chip,
  Menu,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Popover,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedIcon from '@mui/icons-material/Verified';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};


class TrainingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.hideAirportDetails = props?.hideAirportDetails;
    this.categoriesOptions = config?.categoriesOptions;
    this.documentOptions = config?.documentOptions;
    this.statusOptions = config?.statusOptions;
    this.divisionOptions = config?.divisionOptions;
    this.respOptions = config?.respOptions;

    this.state = {
      ...props.data,
      showRespDropDown: false,
      showDivisionDropDown: false,
      showCategoryDropDown: false,
      showDocumentDropDown: false,
      showStatusDropDown: false,
      trainingData: {},
      isScrolling: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "11",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }
  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      trainingData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };
  formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;

  }

  render() {
    return (
      <>
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" ,borderRadius:'3px'}}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer style={{ width: "100%" , maxHeight: 'calc(100vh - 255px)'}} hideScrollbars={false}>
              <Table
                stickyHeader
                size="small"
                aria-label="customized table"
                className="child"
              >
                <TableHead>
                  <TableRow>
                    {this.tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["course"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.trainings?.map((row, index) => {
                      const {
                        _id,
                        course,
                        agreement_date,
                        certificate_issue_date,
                        remarks,
                        training_agreement,
                        training_duration,
                        training_start_date,
                        training_end_date,
                        type_of_certificate_issued
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.onAction(_id);
                          }}
                          className="scroller"
                          ref={this.attachScroller}
                          onMouseDown={this.onScroll}
                          onScroll={this.onMouseMove}
                        >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: "sticky",
                                left: 0,
                                zIndex: 10,
                                backgroundColor:
                                  index % 2 ? "white" : "#F6F7F8",
                                minWidth: "10rem",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={6}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{
                                    typography: "body2",
                                    cursor: "pointer",
                                  }}
                                  onDoubleClick={() => {
                                       this.props.navigate(`/airtrace/training/${_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <Link
                                      onClick={() => {
                                        this.props.navigate(`/airtrace/course/${course?._id}`);
                                      }}
                                    >
                                      {course?.course_id}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          
                            <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                                this.props.navigate(`/airtrace/training/${_id}`);
                              }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              justifyContent='center'
                              spacing={2}
                              key={training_agreement}
                            >
                              <Stack  
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2" >
                                  {training_agreement ? <VerifiedIcon/> :<GppBadIcon/>}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2"
                                 onDoubleClick={() => {
                                    this.props.navigate(`/airtrace/training/${_id}`);
                                  }}>
                                      {agreement_date ? moment(agreement_date).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                 this.props.navigate(`/airtrace/training/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                key={training_duration}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {this.formatSeconds(training_duration)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ cursor: "pointer" }}
                                onDoubleClick={() => {
                                  this.props.navigate(`/airtrace/training/${_id}`);
                                }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                      {training_start_date ? moment(training_start_date).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                                 this.props.navigate(`/airtrace/training/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {training_end_date ? moment(training_end_date).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                           
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.props.navigate(`/airtrace/training/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {type_of_certificate_issued}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                        <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2"
                                 onDoubleClick={() => {
                                    this.props.navigate(`/airtrace/training/${_id}`);
                                  }}>
                                      {certificate_issue_date ? moment(certificate_issue_date).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>


                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2"
                                 onDoubleClick={() => {
                                    this.props.navigate(`/airtrace/training/${_id}`);
                                  }}>
                                      {remarks}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>                          

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{padding:'2px'}}
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.trainings?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.trainings?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_trainings / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                   this.props.navigate("/airtrace/training/" + this.state?.training?._id);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteTraining");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteTrainingPopUp}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <TrainingsTable {...props} navigate={navigate} params={params} />;
}
