import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  Container,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  Popover,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  InputAdornment,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import {
  editAirportDetail,
  getAirportList,
  deleteAirport,
  exportAirports
} from "../../services/airport.service";
import CircularProgress from "@mui/material/CircularProgress";
import { getContinents, getCountries } from "../../services/common.service";
import { getOptionData } from "../../utils/commonUtils";
import ScrollContainer from "react-indiana-drag-scroll";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const TABLE_HEAD = [
  { id: "name", label: i18n.t("name"), alignRight: false, sortable: true },
  {
    id: "continent",
    label: i18n.t("continent"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "country",
    label: i18n.t("country"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_iata",
    label: i18n.t("code_iata"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "code_oaci",
    label: i18n.t("code_oaci"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "is_open",
    label: i18n.t("is_open"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "representative",
    label: i18n.t("btee_sa_representative"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

class Airports extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      airports: [],
      airportData: {},
      open: null,
      page: 1,
      selected: [],
      search: "",
      limit: 100,
      skip: 0,
      total_airports: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: { name: "", country: "" },
      orderBy: { sort_key: "name", sort_order: true },
      showIsOpenDropDown: false,
      deleteAirportPopUp: false,


      // Filter Options
      countriesList: [],
      continentsList: []
    };
    this.confirmOptions = config.confirmOptions;
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }

  async componentDidMount() {
    await this.getAirports();
    this.getCountriesList();
    this.getContinentsList();
  }

  async getAirports(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }

    let airports = await getAirportList(query);
    if (airports.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: airports?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(airports?.error_code))
          : airports?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }
    await this.setState({
      tableLoader: false,
      airports: airports.airports,
      total_airports: airports.count,
    });
  }
  async exportAirportsData() {
    this.setState({
      pageLoader: true,
    });
    let query = {};
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }
    let response = await exportAirports(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    }else{
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t('airports')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      this.setState({
        pageLoader: false,
      });
    }

  }
  
  async getCountriesList() {
    let response = await getCountries();
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.countries) {
      let countriesList = response.countries;
      let options = countriesList?.map((country) => {
        return {
          label: i18n.t('COUNTRIES.'+country?.name),
          value: country?.name,
          phone: country?.dial_code,
          code: country?.code,
        };
      });
      await this.setState({
        countriesList: options
      });
    }
  }
  async getContinentsList() {
    let response = await getContinents();
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.continents) {
      let continentsList = response.continents;
      let options = continentsList?.map((continent) => {
        return {
          label: i18n.t('CONTINENTS.'+continent?.name),
          value: continent?.name,
          code:continent?.code
        };
      });
      await this.setState({
        continentsList: options
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getAirportDataOnchange = debounce(() => {
    this.getAirports("filters");
  }, 500);

  handleRowClick = (airportId) => {
    this.props.navigate(`/airports/airportDetails/${airportId}`);
  };
  handleDropdown = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      airportData: data,
      rowIndex,
    });
  };
  handleCloseDropdown = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };
  handleTableActions(action, data, rowData, key, rowIndex) {
    if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.airports;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        airports: tempData,
      });
      this.updateAirportData(payload, payload?._id, backUpData);
    }
  }
  async updateAirportData(payload, AirportId, backUpData) {
    let response = await editAirportDetail(AirportId, payload);
    if (response && response.error) {
      let tempData = this.state.airports;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        tasks: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airport) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("airport_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async handleDeleteAirport(airportId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteAirport(airportId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getAirports();
      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("airports")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography  className='pageHeader' variant="h4" gutterBottom>
              {i18n.t("airports")}
        </Typography>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{width:'100%',marginRight:'10px'}}>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              background: "white"
            }}
          >
            <AccordionSummary
              style={{ background: "white" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{margin:0}}
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                onChange={(e) => {
                  this.setState({
                    search: e?.target?.value,
                  });
                  this.getAirportDataOnchange();
                }}
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
              <IconButton
                onClick={() => {
                  this.handleExpandClick();
                }}
              >
                <FilterAltIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3} mb={2}>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label={i18n.t("name")}
                        variant="outlined"
                        value={this.state?.filters?.name || ""}
                        onChange={async (e) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              name: e.target.value,
                            },
                          });
                          this.getAirportDataOnchange();
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <Autocomplete
                        required
                        noOptionsText={i18n.t('no_options')}
                        disablePortal
                        options={
                          !this.state?.countriesList
                            ? [{ label: "Loading...", id: 0 }]
                            : this.state?.countriesList
                        }
                        onChange={async (option, value) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              country: value?.value ? value?.value : "",
                            },
                          });
                          this.getAirportDataOnchange();
                        }}
                        value={this.state?.filters?.country && i18n.t("COUNTRIES."+this.state?.filters?.country) || ""}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={option?.code ? `https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x` : null}
                              src={option?.code ? `https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png` : null}
                              alt=""
                            />
                            {option.label} {option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label={i18n.t("country")} />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <Autocomplete
                        required
                        noOptionsText={i18n.t('no_options')}
                        disablePortal
                        options={
                          !this.state?.continentsList
                            ? [{ label: "Loading...", id: 0 }]
                            : this.state?.continentsList
                        }
                        onChange={async (option, value) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              continent: value?.value ? value?.value : "",
                            },
                          });
                          this.getAirportDataOnchange();
                        }}
                        value={this.state?.filters?.continent && i18n.t("CONTINENTS."+this.state?.filters?.continent) || ""}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={option?.code ? `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x` : null}
                              src={option?.code ? `https://flagcdn.com/w20/${option.code?.toLowerCase()}.png` : null}
                              alt=""
                            />
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label={i18n.t("continent")} />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label={i18n.t("code_OACI")}
                        variant="outlined"
                        value={this.state?.filters?.code_oaci || ""}
                        onChange={async (e) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              code_oaci: e.target.value,
                            },
                          });
                          this.getAirportDataOnchange();
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label={i18n.t("code_IATA")}
                        variant="outlined"
                        value={this.state?.filters?.code_iata || ""}
                        onChange={async (e) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              code_iata: e.target.value,
                            },
                          });
                          this.getAirportDataOnchange();
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{marginRight:"10px"}}>
          <Button
              variant="contained"
              startIcon={<SystemUpdateAltIcon />}
              className="BtnWidth"
              onClick={() => {
                this.exportAirportsData();
              }}
            >
              {i18n.t("export_airports")}
            </Button>
          </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/airports/add");
              }}
            >
              {i18n.t("airport")}
            </Button>
          </Stack>
        </Grid>

        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" ,borderRadius:'5px'}}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer style={{ width: "100%" , maxHeight: 'calc(100vh - 370px)'}} hideScrollbars={false}>
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <StyledTableCell
                      style={
                        ["name"].includes(headCell.id)
                          ? this.useStyles?.sticky
                          : {}
                      }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              await this.setState({
                                orderBy: {
                                  sort_key: headCell.id,
                                  sort_order: !this.state.orderBy?.sort_order,
                                },
                              });
                              this.getAirports();
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state.airports.map((row, index) => {
                      const {
                        _id,
                        name,
                        code_iata,
                        code_oaci,
                        country,
                        continent,
                        is_open,
                        representative
                      } = row;

                      return (
                        <StyledTableRow key={_id} tabIndex={-1}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                              <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airports/airportDetails/${_id}`
                                        );
                                      }}
                                    >
                                  {name}
                                  </Link>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {continent && i18n.t("CONTINENTS."+continent)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                {country && i18n.t("COUNTRIES."+country)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align={"center"}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {code_iata}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={code_oaci}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {code_oaci}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ margin: "auto", display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      backgroundColor: getOptionData(
                                        this.confirmOptions,
                                        is_open
                                      )?.color,
                                      padding: 5,
                                      color: "white",
                                      borderRadius: 5,
                                      fontSize: 12,
                                      textWrap: "nowrap",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.handleDropdown(
                                        e,
                                        row,
                                        index,
                                        "showIsOpenDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showIsOpenDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showIsOpenDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                  >
                                    {i18n.t(is_open)}
                                  </span>
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showIsOpenDropDown
                                    )}
                                    anchorEl={this.state.showIsOpenDropDown}
                                    onClose={() => {
                                      this.handleCloseDropdown(
                                        "showIsOpenDropDown"
                                      );
                                    }}
                                  >
                                    {this.confirmOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.handleTableActions(
                                              "update",
                                              option?.value,
                                              this.state.airportData,
                                              "is_open",
                                              this.state.rowIndex
                                            );
                                            this.handleCloseDropdown(
                                              "showIsOpenDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="right"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              style={{ display: "flex", cursor: "pointer" }}
                              direction="row"
                              justifyContent='center'
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {representative}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{padding:'2px'}}
                              onClick={(e) => {
                                this.setState({
                                  airport: row,
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.airports?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        <Grid
          container
          spacing={2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid></Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(this.state.total_airports / this.state.limit)}
              shape="rounded"
              size="large"
              onChange={async (event, page) => {
                await this.setState({
                  skip: (page - 1) * this.state.limit,
                  page,
                });
                this.getAirports();
              }}
              page={this.state.page}
              showFirstButton={true}
              showLastButton={true}
              boundaryCount={2}
              style={{ margin: 20 }}
            />
          </Grid>
          <Grid item style={{ width: "10%" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Limit</InputLabel>
              <Select
                value={this.state.limit}
                label="Limit"
                size="small"
                onChange={async (e) => {
                  await this.setState({
                    ...this.state,
                    limit: e?.target?.value,
                  });
                  this.getAirports();
                }}
              >
                {this.tableLimitOptions?.map((limit) => {
                  return (
                    <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate("/airports/" + this.state.airport._id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            {i18n.t("edit")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({
                deleteAirportPopUp: true,
              });
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
            {i18n.t("delete")}
          </MenuItem>
        </Popover>
        <Dialog
          open={this.state?.deleteAirportPopUp}
          onClose={() => {
            this.setState({
              deleteAirportPopUp: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await this.setState({
                  deleteAirportPopUp: false,
                });
                this.handleDeleteAirport(this.state.airport._id);
              }}
            >
              {i18n.t("proceed")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteAirportPopUp: false,
                });
              }}
              autoFocus
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Airports {...props} navigate={navigate} params={params} />;
}
