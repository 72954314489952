import Swal from "sweetalert2";
import i18n from "../i18n";
import config from "../config";

const getOptionData = (options , value)=>{
    let tempData = options?.filter(option=>option.value === value)?.[0];
    return tempData;
}

const copyToClipBoard=(url)=>{
    navigator.clipboard.writeText(url)
    .then(() => {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("copied_the_link_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    })
    .catch(err => {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("unable_to_copy_link_please_try_Again"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    });
}

export { getOptionData , copyToClipBoard} ;

